@import '../../themes/variables.scss';
@import '../../themes/mixins.scss';

.footer {
  background-color: $primary;
  height: 60px;
  width: 100%;
  color: $white;
  padding: 10px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 321px) {
    height: 70px;
  }
}

@import '../../../../../../themes/_variables';

.tab-header {
  margin-top: 10px;
  margin-bottom: 10px;

  .header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 15px;

    // background-color: #f2f2f2;
    background-color: $theme-color-secondary;

    .img-logo {
      height: 55px;
      // margin: 15px;
    }

    .titulo-header{
      color: $white;
    }

    .btn-exportar{
      background-color: $white;
      color: $theme-color-secondary;
    }
  }
  .gradient {
    background: linear-gradient(
      to right,
      #ffc50a,
      #f7881e,
      #e36823,
      #b4282f,
      #8a287b,
      #6f2e8e,
      #23429b
      // #ffe79d,
      // #f8c9a5,
      // #e6afa9,
      // #d1a9c7,
      // #c7abd2,
      // #adb1d6,
    );
    height: 8px;
  }
}

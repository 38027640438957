// Fonts
$font-family: 'Stag';

// Colors
$primary: #003283;
$primary-hover: #215a99;
$primary-selecionado: rgba(40, 109, 184, 0.5);
$primary-light: #7daee3;
$danger: #e00000;
$sucess: #00af12;
$sucess-light: lightgreen;

$secondary: #77acd7;
$theme-color-primary: #92278f;
$theme-color-secondary: #2d2f93;
$turn-off-the-light: rgba(0, 0, 0, 0.5);
$turn-off-the-lighter: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;
$black2: #00000029;
$white-half-opacity: rgba(255, 255, 255, 0.8);
$gray: #67666a;
$gray-light: #d2d2d2;
$gray-lighter: #f3f3f3;

$brackground: #fafafa;

$amountOfLight: 10%;
$primary-dark: darken($primary, $amountOfLight);
